export const background = require('./background.jpg');
export const contact = require('./contact.png');
export const gallery_1 = require('./nkar1.jpg');
export const gallery_2 = require('./nkar2.jpg');
export const gallery_3 = require('./nkar3.jpg');
export const logo = require('./logo.png');
export const relax_project = require('./relax_project.png');
export const partners_1 = require('./mek.jpg');
export const partners_2 = require('./erku.jpg');
export const partners_3 = require('./ereq.jpg');
export const partners_5 = require('./hing.jpg');
export const partners_6 = require('./vec.jpg');
export const partners_7 = require('./yot.jpg');
export const partners_logo = require('./partners_logo.jpeg');
export const header_back = require('./header_back.png');
export const block_back = require('./blockBAck_2.png');
export const special_background = require('./background2.jpg')





