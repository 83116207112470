export const facebook =
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 0C4.02955 0 0 4.05026 0 9.04626C0 13.5817 3.32386 17.3265 7.65491 17.9807V11.4439H5.42823V9.066H7.65491V7.48372C7.65491 4.86401 8.92473 3.7139 11.0909 3.7139C12.1283 3.7139 12.6769 3.7912 12.9367 3.82657V5.90227H11.459C10.5394 5.90227 10.2183 6.77853 10.2183 7.76621V9.066H12.9134L12.5476 11.4439H10.2183V18C14.6111 17.4009 18 13.6257 18 9.04626C18 4.05026 13.9705 0 9 0Z" fill="white" />
    </svg>

export const instagram =
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.24854 0C2.35429 0 0 2.35646 0 5.25146V12.7515C0 15.6457 2.35646 18 5.25146 18H12.7515C15.6457 18 18 15.6435 18 12.7485V5.24854C18 2.35429 15.6435 0 12.7485 0H5.24854ZM14.25 3C14.664 3 15 3.336 15 3.75C15 4.164 14.664 4.5 14.25 4.5C13.836 4.5 13.5 4.164 13.5 3.75C13.5 3.336 13.836 3 14.25 3ZM9 4.5C11.4817 4.5 13.5 6.51825 13.5 9C13.5 11.4817 11.4817 13.5 9 13.5C6.51825 13.5 4.5 11.4817 4.5 9C4.5 6.51825 6.51825 4.5 9 4.5ZM9 6C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9C6 9.79565 6.31607 10.5587 6.87868 11.1213C7.44129 11.6839 8.20435 12 9 12C9.79565 12 10.5587 11.6839 11.1213 11.1213C11.6839 10.5587 12 9.79565 12 9C12 8.20435 11.6839 7.44129 11.1213 6.87868C10.5587 6.31607 9.79565 6 9 6Z" fill="white" />
    </svg>

export const webex =
    <svg width={20} height={13} viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 0.5L3.41943 3.33997L0.5 6.27433V0.5Z" fill="#605E5C" />
        <path d="M3.65994 3.57434L6.66095 6.49451L3.65994 9.48754V3.57434Z" fill="#C1C1C1" />
        <path d="M3.55526 3.47485L0.57222 6.48622L3.55526 9.3862V3.47485Z" fill="#FFFCFD" />
        <path d="M9.9406 3.59473L6.97853 6.58501L9.9406 9.52214V3.59473Z" fill="#A8A8A8" />
        <path d="M6.75317 6.57214L3.76413 9.58992L6.75317 12.5V6.57214Z" fill="#DEDEDD" />
        <path d="M6.85422 6.68188L9.81629 9.56082L6.85422 12.4979V6.68188Z" fill="#6C6A68" />
        <path d="M19.5 0.5L16.5803 3.33997L19.5 6.27433V0.5Z" fill="#605E5C" />
        <path d="M16.3397 3.57434L13.3387 6.49451L16.3397 9.48754V3.57434Z" fill="#C1C1C1" />
        <path d="M16.4441 3.47485L19.4275 6.48622L16.4441 9.3862V3.47485Z" fill="#FFFCFD" />
        <path d="M10.0591 3.59473L13.0212 6.58501L10.0591 9.52214V3.59473Z" fill="#A8A8A8" />
        <path d="M13.2463 6.57214L16.2357 9.58995L13.2463 12.5V6.57214Z" fill="#DEDEDE" />
        <path d="M13.1456 6.68188L10.1835 9.56082L13.1456 12.4979V6.68188Z" fill="#6C6A68" />
    </svg>



export const tellIcon = <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8981 9.90375L10.9537 8.58437L10.9456 8.58062C10.7928 8.51524 10.626 8.48901 10.4605 8.50428C10.2949 8.51956 10.1358 8.57587 9.9975 8.66812C9.98121 8.67886 9.96555 8.69055 9.95063 8.70312L8.42937 10C7.46562 9.53187 6.47063 8.54437 6.0025 7.59312L7.30125 6.04875C7.31375 6.03312 7.32562 6.0175 7.33687 6.00062C7.42714 5.86268 7.48191 5.70457 7.4963 5.54035C7.5107 5.37614 7.48427 5.21091 7.41937 5.05937V5.05187L6.09625 2.1025C6.01046 1.90454 5.86295 1.73963 5.67574 1.63239C5.48853 1.52516 5.27166 1.48135 5.0575 1.5075C4.21061 1.61894 3.43324 2.03485 2.87058 2.67756C2.30793 3.32026 1.99847 4.1458 2 5C2 9.9625 6.0375 14 11 14C11.8542 14.0015 12.6797 13.6921 13.3224 13.1294C13.9651 12.5668 14.3811 11.7894 14.4925 10.9425C14.5187 10.7284 14.475 10.5116 14.3679 10.3244C14.2607 10.1372 14.096 9.98963 13.8981 9.90375ZM11 13C8.87898 12.9977 6.84549 12.1541 5.3457 10.6543C3.84591 9.1545 3.00232 7.12102 3 5C2.99765 4.38968 3.21753 3.79937 3.61859 3.33931C4.01964 2.87926 4.57444 2.58091 5.17938 2.5C5.17912 2.50249 5.17912 2.505 5.17938 2.5075L6.49188 5.445L5.2 6.99125C5.18686 7.00631 5.17495 7.0224 5.16437 7.03937C5.07032 7.18369 5.01515 7.34987 5.0042 7.52178C4.99325 7.6937 5.02689 7.86553 5.10187 8.02062C5.66812 9.17875 6.835 10.3369 8.00562 10.9025C8.16185 10.9768 8.33468 11.0093 8.50721 10.9968C8.67975 10.9843 8.8461 10.9272 8.99 10.8312C9.00608 10.8205 9.02152 10.8088 9.03625 10.7962L10.5556 9.5L13.4931 10.8156H13.5C13.4201 11.4214 13.1222 11.9773 12.662 12.3794C12.2019 12.7814 11.611 13.0021 11 13Z" fill="white" />
</svg>



export const arrowIcon = <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} viewBox="0 0 24 24" style={{fill: 'white'}}><path d="m12 15.586-4.293-4.293-1.414 1.414L12 18.414l5.707-5.707-1.414-1.414z" /><path d="m17.707 7.707-1.414-1.414L12 10.586 7.707 6.293 6.293 7.707 12 13.414z" /></svg>
